var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bht-header"},[_c('div',{staticClass:"bht-header-fixed"},[_c('div',{staticClass:"bht-header-content"},[_c('img',{staticClass:"bht-header-logo",attrs:{"src":_vm.webInfo.logoUrl,"alt":"logo"}}),_vm._v(" "),_c('div',{staticClass:"bht-header-nav"},_vm._l((_vm.webInfo.webDhlj),function(item,index){return _c('div',{key:index,staticClass:"nav-item",class:{
                        actived:
                            (_vm.isIndex && item.title === '首页') ||
                            item.url === _vm.pathname,
                    },style:({
                        color:
                            (_vm.isIndex && item.title === '首页') ||
                            item.url === _vm.pathname
                                ? _vm.webInfo.defcolor
                                : '',
                    }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.tabClick(item)}}},[_c('span',{staticClass:"nav-title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),(item.clild && item.clild.length > 0)?_c('div',{staticClass:"child-cntent"},_vm._l((item.clild),function(cItem,cIndex){return _c('span',{key:cIndex,on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.tabClick(cItem)}}},[_vm._v("\n                            "+_vm._s(cItem.title)+"\n                        ")])}),0):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"bht-header-contact",style:({color: _vm.webInfo.defcolor})},[_c('div',{staticClass:"tel"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v(" "),_c('div',[_c('div',[_vm._v(_vm._s(_vm.webInfo.phone1))]),_vm._v(" "),(_vm.webInfo.phone2)?_c('div',[_vm._v("\n                            "+_vm._s(_vm.webInfo.phone2)+"\n                        ")]):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }