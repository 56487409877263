<template>
    <div class="def-bht">
        <div
            v-if="['bht'].includes(webInfo.id)"
            class="logo-hidden-con"
        >
            <img
                src="/images/captureImg/size1.png"
                :alt="webInfo.webName"
            />
            <img
                src="/images/captureImg/size2.png"
                :alt="webInfo.webName"
            />
            <img
                src="/images/captureImg/size3.png"
                :alt="webInfo.webName"
            />
            <img
                src="/images/captureImg/size4.png"
                :alt="webInfo.webName"
            />
        </div>

        <HeaderGgzy
            v-if="['HomeMancheng'].includes(webInfo.webStyle)"
        ></HeaderGgzy>

        <HeaderSys
            v-else
            :lindex="lindex"
        ></HeaderSys>

        <!-- 顶部带背景图的装饰部分 -->
        <HeaderApply v-if="banner"></HeaderApply>

        <div class="def-bht-content">
            <div
                class="view"
                :class="{gray}"
            >
                <slot></slot>
            </div>
        </div>

        <OnlineServe v-if="['bht'].includes(webInfo.id)"></OnlineServe>

        <FooterSys v-if="!['HomeMancheng'].includes(webInfo.webStyle)"></FooterSys>
    </div>
</template>
<script>
import HeaderSys from '@components/sys/header-sys';
import HeaderGgzy from '@components/sys/header-ggzy';
import FooterSys from '@components/sys/footer-sys';

import HeaderApply from './header-apply.vue';
import OnlineServe from './online-serve.vue';

export default {
    inject: ['webInfo'],
    components: {
        HeaderSys,
        HeaderGgzy,
        FooterSys,
        HeaderApply,
        OnlineServe,
    },
    props: {
        lindex: {
            type: Number,
            default: 0,
        },
        gray: {
            type: Boolean,
            default: false,
        },
        banner: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ismobile: false,
        };
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            this.ismobile = true;
            return;
        }
    },
};
</script>
<style lang="scss" scoped>
@import '@css/var.scss';

.def-bht {
    width: 100%;

    .logo-hidden-con {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
    }

    .def-bht-content {
        position: relative !important;
        width: 100%;

        .view {
            width: 100%;
            // min-height: calc(100vh - $defBhtHeaderFixedHeight);
            min-height: 700px;

            &.gray {
                background: #f8fafb;
            }
        }
    }
}
</style>
