<template>
    <div class="footer-sys">
        <div class="in_center">

            <div class="top">
                <div class="cols">
                    <ul v-if="webInfo.webXlcp.length !== 0">
                        <li>系列产品</li>
                        <li
                            v-for="(item, index) in webInfo.webXlcp"
                            :key="index"
                        ><a :href="item.url">{{item.title}}</a></li>
                    </ul>

                    <ul>
                        <li>联系我们</li>
                        <li>服务热线
                            <div>工作时间：{{webInfo.phone1}}</div>
                            <div v-if="webInfo.phone2">非工作时间：{{webInfo.phone2}}</div>
                        </li>
                        <li v-if="webInfo.email">电子邮箱：{{webInfo.email}}</li>
                        <li v-if="webInfo.phone3">加盟咨询：{{webInfo.phone3}}</li>
                    </ul>

                    <ul v-if="webInfo.webYqlj.length !== 0">
                        <li>友情链接</li>
                        <li
                            v-for="(item,index) in webInfo.webYqlj"
                            :key="index"
                        ><a
                                :href="item.url"
                                target="_blank"
                            >{{item.title}}</a></li>
                    </ul>

                    <ul v-if="webInfo.webGjxz.length !== 0">
                        <li>工具下载</li>
                        <li
                            v-for="(item, index) in webInfo.webGjxz"
                            :key="index"
                        ><a
                                :href="item.url"
                                target="_blank"
                                :title="item.title"
                            >{{item.title}}</a></li>
                    </ul>

                    <div v-if="webInfo.qrcodeUrl">
                        <img
                            style="width: 111px;height: 111px;"
                            :src="webInfo.qrcodeUrl"
                            alt="二维码"
                        />
                    </div>
                </div>
            </div>

            <p class="bottom">
                <template v-if="ICP">
                    <a
                        target="_blank"
                        href="http://beian.miit.gov.cn/"
                        class="link-item"
                    >
                        {{ICP}}
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </template>
                
                <template v-if="copyright">
                    <a
                        target="_blank"
                        :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + recordcode "
                        class="link-item gov-reg"
                    >
                        <img src="/images/icon-beian.png" />
                        <span>{{copyright}}</span>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </template>
                
                <template v-if="company">
                    @版权所有
                    <a
                        v-if="isCompanyJump || companyUrl.length > 0"
                        class="copyright-company"
                        :href="companyUrl || webInfo.webBqsmLink"
                        target="_blank"
                    >{{company}}</a>
                    <a
                        v-else
                        class="copyright-company"
                        target="_blank"
                    >{{company}}</a>      
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </template>

                <cnzz></cnzz>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['webInfo'],
    props: ['data', 'back'],
    data: function () {
        return {
            loginState: false,
            qrcodeShow: false,
            ICP: '',
            copyright: '',
            company: '',
            friendshipShow: false,
            toolLink: [],
            toolShow: false,
            recordcode: '',
            isCompanyJump: false,  // 是否允许跳转
            companyUrl:'',
        };
    },
    computed: {},
    methods: {},
    created() {},
    mounted: function () {
        this.isCompanyJump = false;
        if (window.location.host.indexOf('zh.syxbxjj') != -1) {
        // } else if (window.location.host.indexOf('8011') != -1) {
            this.ICP = '冀ICP备19020926号-1';
            this.copyright = '冀ICP备19020926号';
            this.company = '圣源祥保险经纪有限公司';
            this.companyUrl = 'http://www.syxbxjj.com/';
        } else {
            this.ICP = this.webInfo.webBah;
            this.copyright = this.webInfo.webWah;
            this.company = this.webInfo.webBqsm;
            this.recordcode = this.webInfo.webWah.match(/\d+/)[0];
            this.companyUrl = '';
            this.isCompanyJump = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.footer-sys {
    background-color: #343842;
    overflow: hidden;

    .in_center {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .top {
        padding: 35px 0;
        border-bottom: 1px solid #828b98;

        p {
            color: #a0a2a5;
        }

        a {
            display: inline-block;
            color: #a0a2a5;
            line-height: 16px;
            padding-right: 5px;
        }

        ul {
            color: #a0a2a5;
            float: left;
            display: block;
            margin: 0 25px;
            font-size: 16px;

            li:first-child {
                font-size: 20px;
            }
        }

        .cols {
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
        }
    }

    .bottom {
        text-align: center;
        color: #a0a2a5;
        padding: 1em 0;

        .link-item {
            display: inline-block;
            text-decoration: none;
            height: 15px;
            line-height: 20px;
        }

        .gov-reg {
            img {
                float: left;
            }

            span {
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
            }
        }

        .copyright-company {
            border-bottom: 1px solid #a0a2a5;
            cursor: pointer;
        }
    }
}
</style>