<template>
    <div class="bht-header">
        <div class="bht-header-fixed">
            <div class="bht-header-content">
                <img
                    class="bht-header-logo"
                    :src="webInfo.logoUrl"
                    alt="logo"
                />

                <div class="bht-header-nav">
                    <div
                        class="nav-item"
                        :class="{
                            actived:
                                (isIndex && item.title === '首页') ||
                                item.url === pathname,
                        }"
                        :style="{
                            color:
                                (isIndex && item.title === '首页') ||
                                item.url === pathname
                                    ? webInfo.defcolor
                                    : '',
                        }"
                        v-for="(item, index) in webInfo.webDhlj"
                        :key="index"
                        @click.prevent.stop="tabClick(item)"
                    >
                        <span class="nav-title">{{ item.title }}</span>

                        <div
                            class="child-cntent"
                            v-if="item.clild && item.clild.length > 0"
                        >
                            <span
                                v-for="(cItem, cIndex) in item.clild"
                                :key="cIndex"
                                @click.prevent.stop="tabClick(cItem)"
                            >
                                {{ cItem.title }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="bht-header-contact" :style="{color: webInfo.defcolor}">
                    <div class="tel">
                        <i class="iconfont">&#xebd3;</i>
                        <div>
                            <div>{{ webInfo.phone1 }}</div>
                            <div v-if="webInfo.phone2">
                                {{ webInfo.phone2 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['webInfo'],
    data: function () {
        return {
            pathname: this.$route.path,
        };
    },
    computed: {
        isIndex() {
            let navList = this.webInfo.webDhlj,
                homeItem = navList[0];

            return ['/', '/index.html', homeItem.url].includes(
                this.$route.path
            );
        },
    },
    methods: {
        tabClick: function (item) {
            if (item.title === '一键询价') {
                if (!this.isIndex) {
                    let navList = this.webInfo.webDhlj,
                        homeItem = navList[0];

                    this.goto({
                        path: `${homeItem.url}${item.url}`,
                    });
                } else {
                    let guaranteEnquiryEL =
                        document.querySelector('.guarante-enquiry');

                    if (guaranteEnquiryEL && guaranteEnquiryEL.offsetTop) {
                        if (window.ActiveXObject || 'ActiveXObject' in window) {
                            document.documentElement.scrollTop =
                                guaranteEnquiryEL.offsetTop - 70;
                        } else {
                            window.scrollTo({
                                top: guaranteEnquiryEL.offsetTop - 70,
                                behavior: 'smooth',
                            });
                        }
                    }
                }

                return;
            }

            if (!item.url) return;

            if (item.clild) return;

            if (item.title === '首页') {
                this.goto('/');
            } else {
                if (item.target === '_self') {
                    // window.location.href = item.url;

                    this.goto(item.url);
                } else {
                    window.open(item.url, item.target);
                }
            }
        },
    },
    mounted: function () {},
};
</script>

<style lang="scss" scoped>
.bht-header {
    height: 90px;

    .bht-header-fixed {
        position: fixed;
        width: 100%;
        min-width: 1200px;
        height: 90px;
        z-index: 999;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.bht-header-content {
    width: 1350px;
    display: flex;
    align-items: center;

    .bht-header-logo {
        flex-shrink: 0;
        height: 40px;
    }

    .bht-header-nav {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .nav-item {
            display: block;
            cursor: pointer;
            font-size: 20px;
            margin-right: 20px;
            transition: all 0.3s;
            position: relative;
            color: #6c6c6c;

            .child-cntent {
                display: none;
                position: absolute;
                left: 0 !important;
                background-color: white;
                border-radius: 5px;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
                padding: 14px;

                &:nth-last-child(1) {
                    margin-right: 0px;
                }

                span {
                    display: block;
                    font-size: 17px;
                    white-space: nowrap;
                    position: relative;
                    display: flex;
                    align-items: center;
                    transition: all 0.3s;
                    color: #6c6c6c;

                    &:hover {
                        
                    }
                }
            }

            &:hover {
                .child-cntent {
                    display: block;
                }
            }

            &.actived {
                
            }
        }
    }

    .bht-header-contact {
        flex-shrink: 0;
        font-size: 16px;

        .tel,
        .email {
            display: flex;
            align-items: center;
            font-size: 20px;
        }

        .iconfont {
            font-size: 22px;
            margin-right: 10px;
            flex-shrink: 0;
        }
    }
}

@media screen and (max-width: 1200px) {
    .bht-header-content .bht-header-nav .nav-item {
        margin-right: 6px;
    }
}
</style>